// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/root.tsx"
);
import.meta.hot.lastModified = "1708481747190.478";
}
// REMIX HMR END

export { Root as default } from "@webstudio-is/react-sdk";
